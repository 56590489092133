import { FormattedMessage } from 'react-intl'

import { NavItemType } from 'types/menu'

import {
  Storefront,
  Dashboard,
  PeopleAltOutlined,
  ChatOutlined,
} from '@mui/icons-material'
import ReviewsBadge from 'components/ReviewsBadge'
import { ROUTES } from 'utils/constants/routes'

const main: NavItemType = {
  id: 'main',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage id="dashboard" />,
      type: 'item',
      url: '/',
      icon: Dashboard,
    },
    {
      id: 'business',
      title: <FormattedMessage id="business" />,
      type: 'item',
      url: ROUTES.BUSINESSES,
      icon: Storefront,
      roles: ['admin', 'moderator'],
    },
    {
      id: 'reviews',
      title: <ReviewsBadge />,
      type: 'item',
      url: ROUTES.REVIEWS,
      icon: ChatOutlined,
      roles: ['admin', 'moderator'],
    },
    {
      id: 'customers',
      title: <FormattedMessage id="customers" />,
      type: 'item',
      url: ROUTES.CUSTOMERS,
      icon: PeopleAltOutlined,
      roles: ['admin', 'moderator'],
    },
    {
      id: 'owners',
      title: <FormattedMessage id="owners" />,
      type: 'item',
      url: ROUTES.OWNERS,
      icon: PeopleAltOutlined,
    },
  ],
}

export default main
